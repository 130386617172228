<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <div class="d-flex f-small sidenav-tab">
      <div @click="activeSidenavTab = 1" class="p-3 flex-grow-1" :class="{ active: activeSidenavTab === 1 }"><span class="ms-3">Allgemeines</span></div>
      <div @click="activeSidenavTab = 2" class="text-center p-3" :class="{ active: activeSidenavTab === 2 }"><i class="fa fa-gear" /></div>
    </div>
    <ul class="navbar-nav navbar-nav-left" :class="{ active: activeSidenavTab === 1 }">
      <SidenavItem v-if="authStore.hasPermission(['Buchungen Einsehen', 'Buchungen Bearbeiten'])" to="/orders" text="Buchungen" icon="fa-receipt" />
      <li class="nav-item">
        <SidenavCollapse collapse-ref="statistic" :is-expanded="['filters', 'dashboard', ''].includes(getRoute)" nav-text="Statistiken" :class="['filters', 'dashboard', ''].includes(getRoute) ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-chart-simple" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem to="/" text="Verkaufszahlen" />
              <SidenavItem to="/utilization-dashboard" text="Auslastungszahlen" />
              <SidenavItem v-if="authStore.hasPermission(['Listen Einsehen', 'Listen Bearbeiten'])" to="/filters" text="Listen" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Gutscheine Einsehen',
          'Gutscheine Bearbeiten',
          'Aktionen Einsehen',
          'Aktionen Bearbeiten',
        ])"
        class="nav-item"
      >
        <SidenavCollapse
          collapse-ref="promotions"
          :is-expanded="getRoute === 'promotions' || getRoute === 'promotion-groups' || getRoute === 'code-check' || getRoute === 'vouchers'" nav-text="Aktionen / Gutscheine"
          :class="getRoute === 'promotions' || getRoute === 'promotion-groups' || getRoute === 'code-check' || getRoute === 'vouchers' ? 'active' : ''"
        >
          <template #icon>
            <i class="fa-solid fa-percent" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Gutscheine Einsehen', 'Gutscheine Bearbeiten'])" to="/vouchers" text="Gutscheine" />
              <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/promotions" text="Aktionen" />
              <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/promotion-groups" text="Aktionsgruppen" />
              <SidenavItem v-if="authStore.hasPermission(['Aktionen Einsehen', 'Aktionen Bearbeiten'])" to="/code-check" text="Aktionscode Überprüfung" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Produkte Einsehen',
          'Produkte Bearbeiten',
          'Artikel Einsehen',
          'Artikel Bearbeiten',
          'Termine Einsehen',
          'Termine Bearbeiten',
        ])" class="nav-item"
      >
        <hr class="m-0 horizontal dark">
      </li>
      <li v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" class="nav-item">
        <SidenavCollapse collapse-ref="event-dates" :is-expanded="['event-dates', 'events'].includes(getRoute)" nav-text="Veranstaltungen" :class="['event-dates', 'events'].includes(getRoute) ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-calendar" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" to="/event-dates" text="Termine" />
              <SidenavItem v-if="authStore.hasPermission(['Termine Einsehen', 'Termine Bearbeiten'])" to="/events" text="Veranstaltungsreihen" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="client && authStore.hasPermission([
          'Produkte Einsehen',
          'Produkte Bearbeiten',
          'Produktgruppen Bearbeiten',
          'Produktgruppen Bearbeiten',
        ])" class="nav-item"
      >
        <SidenavCollapse collapse-ref="products" :is-expanded="getRoute === 'products' || getRoute === 'product-groups'" nav-text="Produkte" :class="getRoute === 'products' || getRoute === 'product-groups' ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-ticket" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Produkte Einsehen', 'Produkte Bearbeiten'])" to="/products" text="Produkte" />
              <SidenavItem v-if="authStore.hasPermission(['Produktgruppen Einsehen', 'Produktgruppen Bearbeiten'])" to="/product-groups" text="Produktgruppen" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Artikel Einsehen',
          'Artikel Bearbeiten',
          'Artikelgruppen Bearbeiten',
          'Artikelgruppen Bearbeiten',
        ])" class="nav-item"
      >
        <SidenavCollapse collapse-ref="articles" :is-expanded="getRoute === 'articles' || getRoute === 'article-groups'" nav-text="Artikel" :class="getRoute === 'articles' || getRoute === 'article-groups' ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-list" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Artikel Einsehen', 'Artikel Bearbeiten'])" to="/articles" text="Artikel" />
              <SidenavItem v-if="authStore.hasPermission(['Artikelgruppen Einsehen', 'Artikelgruppen Bearbeiten'])" to="/article-groups" text="Artikelgruppen" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Umfragen Einsehen',
          'Umfragen Bearbeiten',
          'Newsletter Einsehen',
          'Newsletter Bearbeiten',
          'Aufgabenplanung Einsehen',
          'Aufgabenplanung Bearbeiten',
          'Support-Tickets einsehen',
          'Support-Tickets bearbeiten',
          'Support-Kategorien bearbeiten',
          'Support-Kategorien einsehen',
          'Support-Templates einsehen',
          'Support-Templates bearbeiten',
          'Kunden Einsehen',
          'Kunden Bearbeiten',
          'Mitgliedschaften Einsehen',
          'Mitgliedschaften Bearbeiten',
        ])" class="nav-item"
      >
        <hr class="m-0 horizontal dark">
      </li>
      <li
        v-if="authStore.hasPermission([
          'Kunden Einsehen',
          'Kunden Bearbeiten',
          'Mitgliedschaften Einsehen',
          'Mitgliedschaften Bearbeiten',
        ])" class="nav-item"
      >
        <SidenavCollapse collapse-ref="users" :is-expanded="getRoute === 'users' || getRoute === 'user-groups'" nav-text="Kunden" :class="getRoute === 'users' || getRoute === 'user-groups' ? 'active' : ''">
          <template #icon>
            <i class="fa fa-user" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Kunden Einsehen', 'Kunden Bearbeiten'])" to="/users" text="Übersicht" />
              <SidenavItem v-if="authStore.hasPermission(['Kunden Einsehen', 'Kunden Bearbeiten'])" to="/user-groups" text="Zielgruppen" />
              <SidenavItem v-if="authStore.hasPermission(['Mitgliedschaften Einsehen', 'Mitgliedschaften Bearbeiten'])" to="/membership-types" text="Mitgliedschaften" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Umfragen Einsehen',
          'Umfragen Bearbeiten',
          'Newsletter Einsehen',
          'Newsletter Bearbeiten',
          'Aufgabenplanung Einsehen',
          'Aufgabenplanung Bearbeiten',
        ])"
        class="nav-item"
      >
        <SidenavCollapse collapse-ref="event-dates" :is-expanded="getRoute === 'surveys' || getRoute === 'newsletters' || getRoute === 'tasks'" nav-text="Marketing" :class="['surveys'].includes(getRoute) ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-calendar" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!--              <SidenavItem v-if="authStore.hasPermission(['Umfragen Einsehen', 'Umfragen Bearbeiten'])" to="/surveys" text="Umfragen" /> -->
              <SidenavItem v-if="authStore.hasPermission(['Newsletter Einsehen', 'Newsletter Bearbeiten'])" to="/newsletters" text="Newsletter" />
              <SidenavItem v-if="authStore.hasPermission(['Aufgabenplanung Einsehen', 'Aufgabenplanung Bearbeiten'])" to="/tasks" text="Aufgabenplanung" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <li
        v-if="authStore.hasPermission([
          'Support-Tickets einsehen',
          'Support-Tickets bearbeiten',
          'Support-Kategorien bearbeiten',
          'Support-Kategorien einsehen',
          'Support-Templates einsehen',
          'Support-Templates bearbeiten',
        ])"
        class="nav-item"
      >
        <SidenavCollapse collapse-ref="settings" :is-expanded="['support', 'skategorie', 'email-template'].includes(getRoute)" nav-text="Q&S Ticket System" :class="['support', 'skategorie', 'email-template'].includes(getRoute) ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-sliders" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Support-Tickets einsehen', 'Support-Tickets bearbeiten'])" to="/support" text="E-Mail Tickets" />
            </ul>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Support-Kategorien einsehen', 'Support-Kategorien bearbeiten'])" to="/skategorie" text="Support Kategorien" />
            </ul>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Support-Templates einsehen', 'Support-Templates bearbeiten'])" to="/email-template" text="Automatische Antworten" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
    </ul>
    <ul class="navbar-nav navbar-nav-right" :class="{ active: activeSidenavTab === 2 }">
      <li
        v-if="authStore.hasPermission([
          'Mitarbeiter Einsehen',
          'Mitarbeiter Bearbeiten',
          'Berechtigungen Einsehen',
          'Berechtigungen Bearbeiten',
        ])"
        class="nav-item"
      >
        <SidenavCollapse collapse-ref="admins" :is-expanded="['admin-groups', 'admins', 'roles'].includes(getRoute)" nav-text="Mitarbeiter" :class="['admin-groups', 'admin', 'roles'].includes(getRoute) ? 'active' : ''">
          <template #icon>
            <i class="fa-solid fa-user-gear" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <SidenavItem v-if="authStore.hasPermission(['Mitarbeiter Einsehen', 'Mitarbeiter Bearbeiten'])" to="/admins" text="Übersicht" />
              <SidenavItem v-if="authStore.hasPermission(['Berechtigungen Einsehen', 'Berechtigungen Bearbeiten'])" to="/roles" text="Berechtigungen" />
              <SidenavItem v-if="authStore.hasPermission(['Mitarbeiter Einsehen', 'Mitarbeiter Bearbeiten'])" to="/admin-groups" text="Positionen" />
            </ul>
          </template>
        </SidenavCollapse>
      </li>
      <SidenavItem v-if="authStore.hasPermission(['Mandanten Einsehen', 'Mandanten Bearbeiten'])" to="/clients" text="Mandanten" icon="fa-users" />
      <SidenavItem v-if="authStore.hasPermission(['Shops Einsehen', 'Shops Bearbeiten'])" to="/shops" text="Shops" icon="fa-store" />
      <SidenavItem v-if="authStore.hasPermission(['Räume Einsehen', 'Räume Bearbeiten'])" to="/rooms" text="Räume" icon="fa-person-shelter" />
      <SidenavItem v-if="authStore.hasPermission(['Saalpläne Einsehen', 'Saalpläne Bearbeiten'])" to="/plans" text="Saalpläne" icon="fa-pen-ruler" />
      <SidenavItem v-if="authStore.hasPermission(['Zahlungsarten Einsehen', 'Zahlungsarten Bearbeiten'])" to="/payments" text="Zahlungsarten" icon="fa-credit-card" />
      <SidenavItem v-if="authStore.hasPermission(['Versandarten Einsehen', 'Versandarten Bearbeiten'])" to="/shipping-methods" text="Versandarten" icon="fa-truck" />
      <SidenavItem v-if="authStore.hasPermission(['Preislisten Einsehen', 'Preislisten Bearbeiten'])" to="/prices" text="Preislisten" icon="fa-euro" />
      <SidenavItem v-if="authStore.hasPermission(['Auswahllisten Einsehen', 'Auswahllisten Bearbeiten'])" to="/options" text="Auswahllisten" icon="fa-list" />
      <SidenavItem v-if="authStore.hasPermission(['Besteuerung Einsehen', 'Besteuerung Bearbeiten'])" to="/taxes" text="Besteuerung" icon="fa-hand-holding-dollar" />
      <SidenavItem v-if="authStore.hasPermission(['Erlösgruppen Einsehen', 'Erlösgruppen Bearbeiten'])" to="/sales-groups" text="Erlösgruppen" icon="fa-filter-circle-dollar" />
      <SidenavItem v-if="authStore.hasPermission(['Datev Export'])" to="/export/export-datev" text="Datev Export" icon="fa-download" />
    </ul>
  </div>
</template>

<script setup>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavItem from "./SidenavItem.vue";
import { useAuthStore } from "~/stores/AuthStore";

const client = useState("client");
const authStore = useAuthStore();
const activeSidenavTab = ref(1);
const settingsPageRoutes = [
  "clients",
  "shops",
  "rooms",
  "plans",
  "payments",
  "shippings-methods",
  "prices",
  "options",
  "taxes",
  "sales-groups",
  "admins",
  "roles",
  "admin-groups",
  "export",
];

const getRoute = computed(() => {
  const route = useRoute();
  return route.path.split("/")[1];
});

if (settingsPageRoutes.includes(getRoute.value)) activeSidenavTab.value = 2;
</script>
